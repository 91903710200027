@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

*, body, html {
  font-family: 'Montserrat', sans-serif;
}

body,
container,
html {
    overflow-x: hidden;
}

img {
  width: 100%;
  height: auto;
}

.primary_color {
  color: #539165;
}

.secound_color {
  color: #3F497F;
}

.four_color {
  color: #F8F5E4;
}

/* header */

.header.container {
  padding: 20px 0px;
}

.top_header {
  background-color: #3F497F;
  color: #F8F5E4;
  display: block;
  line-height: 30px;
  padding: 5px 0px 10px;
}

.top_phone, .top_email {
  display: inline-block;
}

.top_phone {
  border-right: 1px solid #539165;
  padding-right: 10px;
}

.top_phone svg {
  margin-right: 10px;
  color: #F7C04A;
}

.top_email svg {
  margin: 0 10px;
  color: #F7C04A;
}

.social_top {
  text-align: right;
}

.social_top svg {
  color: #F8F5E4;
  margin: 0 6px;
}

.social_top svg:hover {
  color: #F7C04A;
}

/* menu */

.menu.col-lg-8.col-md-8.col-8 {
  display: flex;
  align-items: center;
  justify-content: right;
}
.menu {
  font-family: 'Montserrat', sans-serif;
}

.menu a.nav-link {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px 7px;
}

.menu a.nav-link:hover {
  color: #539165;
}

.menu a.nav-link::before {
  content: "";
  min-width: 10px;
  height: 2px;
  background-color: #F7C04A;
  position: absolute;
  bottom: 0;
  visibility: hidden;
  transition: all;
  transition-duration: 1.2s;
}

.menu a.nav-link:hover::before{
  min-width: 50px;
  visibility: visible;
}

button.navbar-toggler.collapsed {
  border: 0;
}

.offcanvas-body {
  font-size: 1.2rem;
}

a.special_menu.nav-link {
  font-weight: 600;
  color: #3F497F;
}

/* slider show */

.slideshow {
  background-color: #000;
  opacity: 1;
  min-height:100%;
}

img.slider.d-block.w-100 {
  height: 700px;
  object-fit: fill;
  opacity: 0.4;
}

span.carousel-control-next-icon, span.carousel-control-prev-icon {
  width: 4rem;
  height: 4rem;
}

.carousel-caption {
  top: 35%
}

.carousel-caption span {
  font-size: 3.2rem;
  margin-bottom: 20px;
}

.carousel-caption .button {
  margin-top: 20px;
}

/* about */
.about {
  padding: 150px 0px 50px 0px;
}

.about h1 {
  margin: 15px 0;
  font-weight: 700;
}

.about span {
  color: #539165;
}

.about_content {
  padding-left: 30px;
}

/* featured */
.featured {
  margin-bottom: 100px;
}

.featured_block i {
  color: #3F497F;
  font-size: 50px;
  float: left;
  margin: 10px 15px 30px;
}

.featured_block span {
  color: #3F497F;
  font-size: 1.1rem;
  font-weight: 700;
}

.featured_block p {
  margin-top: 10px;
}

.featured_block:hover span {
  color: #F7C04A;
}

.featured_block:hover svg {
  color: #F7C04A;
}

/* steps */
.steps {
  background-color: #F8F5E4;
  background-image: url(https://vsoft.drenaj-limfatic-braila.ro/public/images/assets/steps-frame.webp);
  background-position: center right;
  background-repeat: no-repeat;
  padding: 160px 0;
}

.steps span {
  color: #F7C04A;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 3.4px;
  margin-bottom: 40px;
}

.steps h2 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

.step_block {
  background-color: #fff;
  background-image: linear-gradient(#3F497F,#3F497F);
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: .4s;
  padding: 35px 25px 30px 28px;
  min-height: 420px;
  justify-content: flex-start;
  transition: .5s;
}

.step_block:hover {
  background-size: 100% 100%;
  transition: .5s;
  color: #fff;
}

.step_block:hover span {
  -webkit-text-stroke: 1px rgba(255,255,255,1);
}

.step_block span {
  -webkit-text-stroke: 1px rgba(0,0,0,.08);
  font-size: 120px;
  line-height: 1;
  font-weight: 800;
  color: transparent;
}

.step_block h3 {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 30px 0px;
}

/* what */
.what {
  padding: 120px 0px 50px;
}

.what span {
  color: #F7C04A;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 3.4px;
  margin-bottom: 40px;
}

.what h2 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

.what h3 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

.what h4 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

/* why */
.why {
  background-color: #539165;
  color: #F8F5E4;
  padding: 120px 0px;
}

.why span {
  color: #F7C04A;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 3.4px;
  margin-bottom: 40px;
}

.why h2 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

.why h3 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

.why h4 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

/* Call to action */

.cta {
  background-image: url(https://vsoft.drenaj-limfatic-braila.ro/public/images/assets/cta.webp);
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: auto auto;
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: auto auto;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #F8F5E4;
  padding: 120px 0px;
  text-align: center;
}

.cta span {
  color: #F7C04A;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 3.4px;
  margin-bottom: 40px;
}

.cta h5 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 20px 0px;
}

/* group */
.group {
  padding: 120px 0px;
  text-align: left;
}
.group span {
  color: #F7C04A;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 3.4px;
  margin-bottom: 40px;
}

.group h6 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 20px 0px 25px;
}

/* faq */
.faq h6 {
  font-size: 20px;
  line-height: 2rem;
}

.faq span {
  color: #539165;
}

.faq .card {
  border: 1px solid #F8F5E4;
  border-radius: 0;
  transition: all .3s ease;
}

.faq .card:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  -webkit-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out
}

.faq .card-link {
    color: #3F497F;
    border-bottom: 1px solid #3F497F;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
}

.faq .card:hover .card-link{
  color: #F7C04A;
  border-bottom: 1px solid #F7C04A;
}

.card:hover h6 {
  color: #3F497F;
  -webkit-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out
}

/* testimonials */
.testimonials h6 {
  font-size: 20px;
  line-height: 2rem;
}

.testimonials span {
  color: #539165;
}

.testimonials .card {
  border: 1px solid #F8F5E4;
  border-radius: 0;
  transition: all .3s ease;
}

.testimonials .card:hover {
  -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  -webkit-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out
}

.testimonials .card-link {
    color: #3F497F;
    border-bottom: 1px solid #3F497F;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
}

.testimonials .card:hover .card-link{
  color: #F7C04A;
  border-bottom: 1px solid #F7C04A;
}

.card:hover h6 {
  color: #3F497F;
  -webkit-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out
}

/* appointment */

.appointment {
  color: #F8F5E4;
  position: relative;
}

.appointment a {
  color: #F7C04A;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.appointment a:hover {
  color: #F8F5E4;
}

.appointment_content {
  bottom: 0;
  position: absolute;
  padding: 30px;
}

/* pages */

.page_block {
  padding: 50px 0px;
}

.page_block h2 {
  margin: 25px 0;
  font-weight: 700;
}

.page_block h3 {
  margin: 25px 0;
  font-weight: 700;
}

.page_block h4 {
  margin: 25px 0;
  font-weight: 700;
}

.page_block h5 {
  font-size: 36px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: -1px;
  margin: 30px 0px;
}

.page_block span {
  color: #539165;
}

.page_heading {
  background-image: url(https://vsoft.drenaj-limfatic-braila.ro/public/images/assets/page_heading.webp);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #F8F5E4;
  text-align: center;
  padding: 150px 0px;
}

ol.breadcrumb {
  margin: 0px auto 20px;
  display: inline-flex;
}

.breadcrumb a {
  color: #F7C04A;
  text-decoration: none;
}

.breadcrumb {
  text-align: center;
}

/* price */ 

.price {
  padding: 50px 10px;
  text-align: center;
  border: 1px solid #F8F5E4;
}

.price:hover {
  border: 1px solid #F7C04A;
}

.price h3 {
  color: #F7C04A;
  font-size: 1rem;
}

.price h4 {
  color: #3F497F;
  font-size: 2.8rem;
  font-weight: 800;
}

.price span {
  color: #3F497F;
  font-size: 1rem;
}

.price ul {
  padding: 0;
}

.price li {
  font-size: 0.8rem;
  list-style-type: none;
  margin: 20px 0px;
}

/* gallery */

.gallery_list img {
  padding: 5px;
  margin: 5px 0px;
}

.faq h2 {
  margin: 5px 0;
  font-weight: 700;
}

.faq .accordion-button {
  background-color: #F8F5E4;
}

.contact_details svg {
  margin: 0px 7px;
}

/* cookies */

.CookieConsent a {
  color: #F8F5E4;
}

/* footer */

.footer {
  background-color: #3F497F;
  padding: 120px 0 50px;
}

.footer_nav {
  text-align: left;
}

.footer_nav h6 {
  color: #F8F5E4;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.footer_nav ul {
  padding: 0;
  margin-bottom: 50px;
}

.footer_nav li {
  color: #F7C04A;
  list-style-type: none;
  margin: 7px 0px;
}

.footer_nav a {
  color: #F8F5E4;
  text-decoration: none;
}

.footer_nav a:hover {
  color: #F7C04A;
}

.copyright, .development {
  color: #F8F5E4;
  font-size: 0.8rem;
}

.development {
  text-align: right;
}

.development img {
  max-width: 120px;
  margin-left: 7px;
}

/* media responsive */

@media (min-width: 769px) and  (max-width: 1024px) {
  .menu a.nav-link {
    font-size: 1.1rem;
  }
  
  img.slider.d-block.w-100 {
    height: auto;
  }
  .group h6 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 768px)  {
  /* header */
  .header.container {
    padding: 30px 0px;
  }
  .logo img {
      max-width: fit-content;
  }
  img.logo_footer  {
    margin-bottom: 40px;
  }
  .top_header {
    text-align: center;
  }
  .top_phone {
    margin: 10px 0 5px 0;
    border-right: 0;
  }
  .top_email svg {
    margin: 0 10px 0 0;
  }
  .social_top {
    text-align: center;
    margin: 10px 0;
  }
  img.logo {
    width: 300px;
    margin: 0px 20px;
  }

  /* menu */
  a.nav-link {
    border-bottom: 1px solid #F7C04A;
  }

  /* slider */
  img.slider.d-block.w-100 {
    height: auto;
  }
  .carousel-caption {
    top: 7%
  }
  .carousel-caption span {
    font-size: 1.7rem;
  }
  .carousel-caption p {
    font-size: 0.9rem;
  }
  .slideshow .carousel-indicators {
    display: none;
  }
  .about, .steps, .what, .why, .group, .footer {
    padding: 50px 0px;
    text-align: center;
  }
  .about_content {
    margin: 30px 0px;
    padding: 0px;
  }
  .featured_block  {
    margin-bottom: 30px;
  }
  .step_block {
    margin-bottom: 50px;
  }
  .testimonials .card {
    margin-bottom: 40px;
  }
  .page_block img {
    margin-bottom: 30px;
  }
  .what img {
    margin-top: 30px;
  }
  .why img {
    margin-bottom: 30px;
  }
  .page_heading {
    background-attachment: scroll;
  }
  .image-set img {
    max-width: 160px;
  }
  .footer img {
    width: 180px;
  }
  .copyright, .development, .footer_nav, .page_block {
    text-align: center;
  }
  
  .about span {
	color: #000;
  }
  .what span {
	color: #000;
  }
  .group span {
	  color: #000;
	}
  .why span {
	color: #fff;
  }
  .social_top a {
	margin: 0px 10px;
  }
  .social_top svg {
    margin: 0 10px;
  }
}